<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card class="card-accent-primary" header-tag="header" footer-tag="footer">
          <b-button variant="secondary" style="margin-right: 5px;" size="md" title="başla" @click="f_startTextPresentation()">
            <img src="@/icon/672008.png" style="width: 2em;" />
          </b-button>
          <b-button variant="secondary" style="margin-right: 5px;" size="md" title="durdur" @click="f_waitTextPresentation()">
            <img src="@/icon/7149.png" style="width: 2em;" />
          </b-button>
          <b-button title="Hızı artır" style="margin-right: 5px;" variant="secondary" size="md" @click="f_textPresentSpeed('+')">
            <img src="@/icon/2707569.png" style="width: 2em;" /> + ({{ d_readText.speed }})
          </b-button>
          <b-button title="Hızı azalt" style="margin-right: 5px;" variant="secondary" size="md" @click="f_textPresentSpeed('-')">
            <img src="@/icon/2707569.png" style="width: 2em;" /> - ({{ d_readText.speed }})
          </b-button>
          <b-button v-if="d_readText.row !== -1 && !d_readText.status" variant="secondary" style="margin-right: 5px;" title="başa dön" size="md" @click="f_goToInitial()">
            <img src="@/icon/453632.png" style="width: 2em;" />
          </b-button>
          <b-button variant="secondary" style="margin-right: 5px;" size="md" title="geri git" @click="f_goBack()">
            <img src="@/icon/615577.png" style="width: 2em; transform: scaleX(-1); -webkit-transform: scaleX(-1);" />
          </b-button>
          <b-button variant="secondary" style="margin-right: 5px;" size="md" title="ileri git" @click="f_goForward()">
            <img src="@/icon/615577.png" style="width: 2em;" />
          </b-button>
          <b-button variant="secondary" style="margin-right: 5px;" size="md" title="sonlandır" @click="f_stopTextPresentation()">
            <img src="@/icon/1890926.png" style="width: 2em;" />
          </b-button>
          <template>
            Toplam Satır:
            <strong style="color: rgba(183, 178, 145, 1);">{{ (parseInt(d_readText.row) + 1) + ' / ' + d_readText.text.split('\n').length }}</strong>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="1">Satır</b-col>
      <b-col sm="12" lg="2">
        <input type="number" name="" style="width: 100px; height: 35px; text-align: center; border: solid 1px green; box-shadow: -1px -1px 2px" title="Kaç satır görünsün" v-model="d_readText.how_many_rows">
      </b-col>
      <b-col sm="12" lg="1">Font</b-col>
      <b-col sm="12" lg="2">
        <input type="number" name="" style="width: 100px; height: 35px; text-align: center; border: solid 1px green; box-shadow: -1px -1px 2px" title="Font Boyutu" v-model="d_readText.font_size">
      </b-col>
      <b-col sm="12" lg="1">Yukarıya uzaklık</b-col>
      <b-col sm="12" lg="2">
        <input type="number" name="" style="width: 100px; height: 35px; text-align: center; border: solid 1px green; box-shadow: -1px -1px 2px" title="Yukarıya Uzaklık" min="0" max="12" v-model="d_readText.margin_top">
      </b-col>
    </b-row>
    <b-card class="card-accent-primary" header-tag="header" footer-tag="footer">
      <b-textarea v-model="d_readText.text"></b-textarea>
    </b-card>
    <b-row>
      <b-col cols="12">
        <b-card class="card-accent-primary" header-tag="header" footer-tag="footer" :style="'margin-top: ' + d_readText.margin_top + 'px;'">
          <b-row v-for="(r, r_ind) in d_readText.show_lines" :style="(parseInt(d_readText.row) + r_ind + 1) % 2 === 0 ? 'background-color: #f3f7f3;' : 'background-color: #e5e5e5;'">
            <b-col cols="1">
              {{ parseInt(d_readText.row) + r_ind + 1 }}
            </b-col>
            <b-col cols="11">
              <span :style="f_calculateRowStyle(r_ind)">
                {{ r ? r : '----' }}
              </span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col :cols="d_readText.right_col"></b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  default as Modal
} from "@/components/widgets/Modal";

export default {
  name: "ReadVideoText",
  computed: {
    ...mapGetters({
      StoreLang: "StoreLang"
    })
  },
  data () {
    return {
      d_readText: {
        'margin_top': '0',
        'left_col': 4,
        'middle_col': 4,
        'right_col': 4,
        'text': '',
        'row': -1,
        'status': 0,
        'speed': 1500,
        'how_many_rows': 7,
        'show_lines': [],
        'font_size': '20'
      },
      d_readTextInterval: 0
    };
  },
  mounted: function () {
    if (localStorage.getItem('read_text')) {
      this.d_readText.text = localStorage.getItem('read_text');
    }
  },
  created: function () {},
  destroyed () {
    window.clearInterval(this.d_readTextInterval);
  },
  methods: {
    f_calculateRowStyle: function (r_ind) {
      let style = 'font-size: ' + this.d_readText.font_size + 'px;';
      /*
        if (r_ind === 0) {
          style += 'color: green;';
        }
      */
      return style;
    },
    f_waitTextPresentation: function () {
      this.d_readText.status = 0;
      window.clearInterval(this.d_readTextInterval);
    },
    f_goBack: function () {
      if (this.d_readText.row !== -1) {
        let text_rows = this.d_readText.text.split('\n');
        this.d_readText.row -= 1;
        this.d_readText.show_lines = [];
        let to = this.d_readText.row + parseInt(this.d_readText.how_many_rows);
        for (let i = this.d_readText.row; i < to; i++) {
          this.d_readText.show_lines.push(text_rows[i]);
        }
      }
    },
    f_goForward: function () {
      let text_rows = this.d_readText.text.split('\n');
      if (this.d_readText.row !== text_rows.length - parseInt(this.d_readText.how_many_rows)) {
        this.d_readText.row += 1;
        this.d_readText.show_lines = [];
        let to = this.d_readText.row + parseInt(this.d_readText.how_many_rows);
        for (let i = this.d_readText.row; i < to; i++) {
          this.d_readText.show_lines.push(text_rows[i]);
        }
      }
    },
    f_goToInitial: function () {
      this.d_readText.row = -1;
      this.d_readText.show_lines = [];
    },
    f_stopTextPresentation: function () {
      this.d_readText.status = 0;
      window.clearInterval(this.d_readTextInterval);
    },
    f_startTextPresentation: function () {
      if (!this.d_readText.status) {
        this.d_readText.status = 1;
        this.d_readTextInterval = setInterval(function () {
          this.f_textPresent();
        }.bind(this), this.d_readText.speed);
      }
    },
    f_textPresent: function () {
      this.d_readText.show_lines = [];
      let text_rows = this.d_readText.text.split('\n');
      if (this.d_readText.row === text_rows.length - parseInt(this.d_readText.how_many_rows)) {
        this.d_readText.row = -1;
      } else {
        this.d_readText.row += 1;
      }
      let to = this.d_readText.row + parseInt(this.d_readText.how_many_rows);
      for (let i = this.d_readText.row; i < to; i++) {
        this.d_readText.show_lines.push(text_rows[i]);
      }
      // console.log(this.d_readText.row);
      if (this.d_readText.row === -1) {
        this.f_stopTextPresentation();
      }
    },
    f_textPresentSpeed: function (type) {
      if (type === '-') {
        this.d_readText.speed += 50;
      } else {
        // +
        this.d_readText.speed -= 50;
      }
      window.clearInterval(this.d_readTextInterval);
      this.f_startTextPresentation();
    }
  },
  watch: {
    'd_readText.text': function () {
      if (this.d_readText.text) {
        localStorage.setItem('read_text', this.d_readText.text);
      } else {
        localStorage.removeItem('read_text');
      }
    },
    'd_readText.font_size': function () {
      this.$forceUpdate();
    }
  },
  components: {
    Modal
  }
};

</script>

<style type="text/css">


</style>

